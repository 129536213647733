.nav {
    // position: fixed;
    z-index: 10;

    left: 0px;
    top: 0px;
    bottom: 0px;

    display: flex;
    flex-direction: row;

    // align-items: center;
    // justify-content: center;

    background-color: #daffc5;
    border-bottom: 1px solid #b2e097;
    // background-color: rgba($color: #005031, $alpha: 1);
    grid-column-start: 1;
    grid-column-end: 3;

    .nav-padding {
        display: flex;
        flex-direction: row;
        flex-grow: 1;
        padding: 10px;
    }

    .nav-inner {
        display: flex;
        flex-direction: row;
        flex-grow: 1;
        justify-content: center;
        align-items: center;

        .nav-logo-parent {
            width: 60px;
        }
        .nav-login {
            flex-grow: 1;
        }
        .nav-user-parent {
            width: 60px;
            .icon {
                font-size: 30px;
                color: #008145;
            }
        }
    }
    .nav-title {
        display: flex;
        flex-direction: row;
        align-items: center;
        // justify-content: center;

        flex-grow: 1;
        font-size: 30px;
        color: #008145;
        p {
            margin: 0px 10px;
        }
    }
    .nav-login {
        // border: 1px solid red;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: right;

        .link {
            margin-left: 20px;
        }
        .nav-icon-profile {
            font-size: 30px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            color: #fe61a3;

            &.profile-none {
                color: #999999;
            }
        }
    }

    .nav-menu {
        position: absolute;
        z-index: 100;

        top: 60px;
        left: 0px;
        right: 0px;
        min-width: 300px;
        width: 300px;
        // height: 100%;
        bottom: 0px;

        background-color: rgba($color: #eeeeee, $alpha: 1);

        display: flex;
        flex-direction: column;
        .link {
            margin-right: 0px;
        }
        .nav-link {
            font-size: 30px !important;
            text-align: left;
        }
        .nav-login {
            padding: 10px;
        }
        .nav-links {
            padding: 10px;
        }
        .side-nav-button {
            // padding: 5px;
            // width: 48px;
            width: 100%;
            height: 48px;
            border: 1px solid #aaaaaa;
            border-radius: 10px;
            background-color: #dddddd;
            margin-bottom: 5px;
            padding: 0px 5px;

            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            &:hover {
                border: 1px solid #aaaaaa;
                box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24), 0 2px 10px 0 rgba(0, 0, 0, 0.19);
            }
            .icon {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                // text-align: center;
                font-size: 36px;
                color: #daffc5;
                svg {
                    margin-right: 10px;
                }
                &:hover {
                    color: #4caf50;
                }
            }
        }
    }
    &.mobile {
       
        .nav-title {
            justify-content: center;
            font-size: 20px;
        }
        .nav-login {
            justify-content: left;
        }
        .link {
            margin-right: 10px;
        }
        .nav-user-parent {
            .link {
                margin: 0px;
            }
        }
        .nav-icon-profile {
            width: 60px;
            font-size: 30px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            color: #fe61a3;
            
            &.profile-none {
                color: #999999;
            }
            
        }
    }
}

.nav-logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    width: 60px;

    .icon {
        font-size: 30px;
        color: #008145;
    }
}

.fake-space {
    .icon {
        font-size: 30px;
        color: rgba($color: #000000, $alpha: 0);
    }
}

.hamburger {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    width: 60px;
    .icon {
        font-size: 30px;
        color: rgb(19, 138, 102);
        &:hover {
            // color: rgb(75, 248, 196);
        }
    }
}
