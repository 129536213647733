.button {
    background-color: #4caf50; /* Green */
    border: none;
    color: #333333;
    padding: 12px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    font-weight: 700;
    border: 1px solid #333333;

    // width: 48px;
    // height: 48px;
    border: 1px solid #aaaaaa;
    border-radius: 10px;
    background-color: #dddddd;

    &:hover {
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24), 0 2px 10px 0 rgba(0, 0, 0, 0.19);
    }
}
