.link {
    
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: center;
    color: #ffffff;
    font-size: 20px;
    a {
        // width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: rgb(19, 138, 102);
        text-decoration: none;
        &:hover {
            // color: rgb(75, 248, 196);
            // border-bottom: solid 1px rgb(75, 248, 196);
        }
    }
    &.title {
        font-size: 34px;
    }
    &.selected {
        a {
            // color: rgb(75, 248, 196);
            // border-bottom: solid 1px rgb(75, 248, 196);
        }
    }
}
