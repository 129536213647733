.page-login {
    background-color: #fefefe;
}

.form {
    background-color: #ffffff;
    border: 1px solid #dddddd;
    max-width: 300px;
    margin: 0 auto;
    padding: 20px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24), 0 2px 10px 0 rgba(0, 0, 0, 0.19);


    .form-group {
        width: 100%;
        margin-bottom: 10px;
        label {
            display: block;
            text-align: left;
            font-weight: 700;
            margin-bottom: 5px;
        }
        input {
            width: 100%;
            height: 30px;

            &:invalid {
                // border: 2px dashed red;
            }

            &:valid {
                // border: 2px solid black;
            }
        }
    }
    .forgot {
        font-size: 14px;
    }
    .divider {
        width: 100%;
        border-top: 2px solid #eeeeee;
        margin-top: 10px;
        margin-bottom: 10px;
        height: 1px;
    }
}
