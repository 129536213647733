@import url("https://fonts.googleapis.com/css2?family=Tourney:wght@500;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;1,300&display=swap");

* {
  -webkit-overflow-scrolling: touch;
}

body {
  margin: 0;
  font-family: "Roboto", cursive;
  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  //   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  //   sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-weight: 900;
}

.root {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  background: rgba($color: #000000, $alpha: 0);
}

.App {
  width: 100%;
  overflow: hidden !important;
  display: flex !important;
  flex-flow: column nowrap !important;
  position: absolute !important;
  inset: 0px !important;
}

.container {
  // display: flex;
  // flex-wrap: nowrap;
  // flex-direction: row;
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;
  // pointer-events: none;

  display: grid;
  grid-template-columns: 60px 1fr;
  grid-template-rows: 60px 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  // @media screen and (min-width: 1000px) {
  //   grid-template-columns: 1fr;
  //   grid-template-rows: 60px 1fr;
  // }
}



p,
h1,
h2,
h3 {
  &.center {
    text-align: center;
  }
}

div {
  &.center {
    text-align: center;
    margin: 0 auto;
  }
}

::-webkit-scrollbar {
  width: 0.5rem;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}


.flex-grow {
  flex-grow: 1;
}
