.route-link {
    width: 48px;
    height: 48px;
    border: 1px solid #aaaaaa;
    border-radius: 10px;
    background-color: #dddddd;
    margin-bottom: 5px;
    overflow-x: visible;

    .inner {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: left;

        // width: 200px;
    }

    .icon-parent {
        min-width: 48px;
        min-height: 48px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .expand-parent {
        flex-grow: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: left;

        font-size: 24px;
        text-align: left;
        margin-left: 20px;
    }
    .icon {
        // display: flex;
        // flex-direction: row;
        // align-items: center;
        // justify-content: center;
        // text-align: center;
        font-size: 30px;
        color: #daffc5;
        &:hover {
        }
    }

    // display: flex;
    // flex-direction: column;
    // align-items: center;
    // justify-content: center;
    // color: #ffffff;
    // font-size: 20px;
    a {
        color: #333333;
        text-decoration: none;
        // width: 100%;
        // display: flex;
        // flex-direction: column;
        // align-items: center;
        // justify-content: center;
        // color: rgb(19, 138, 102);
        &:hover {
            // color: rgb(75, 248, 196);
            // border-bottom: solid 1px rgb(75, 248, 196);
        }
    }
    &.expand {
        width: 180px;
    }

    &:hover {
        border: 1px solid #aaaaaa;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24), 0 2px 10px 0 rgba(0, 0, 0, 0.19);

        .icon {
            color: #4caf50;
        }
    }
}